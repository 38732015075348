.error-layout {
    margin: 50% auto;
    width: 90%;
    padding: 10px;
    text-align: center;

    @media screen and (min-width: 768px) {
        margin: 25% auto;
    }

    .error-message {
        font-size: 1.5rem;
        font-weight: 400;
    }

    button {
       font-size: 1rem;
       margin: 0.8rem;
    }

    p {
        font-size: 1rem;
    }

}

.spinn {
    background-color: #31af8f !important;
}

Spinner {
    background-color: #31af8f !important;
}