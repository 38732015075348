* {
  font-family: GraphikRegular;
}

.panel {
  border-radius: 6px;
  background-color: #ffffff;
  height: 100%;
  font-size: 13px;
  position: relative;
}

.header {
  font-size: 25px;
}

.modal-backdrop {
  position: fixed !important;
}

table {
  font-size: 14px;
}

tr {
  font-size: 12px;
  cursor: pointer;
}

th {
  text-transform: uppercase;
}

.table {
  margin: 0;

  .col-sm-12 {
    padding: 0;
  }

  .table-actions {
    display: flex;

    span {
      margin-right: 8px;
      padding: 6px 6px;
      border-radius: 3px;
      background-color: #31af8f;

      &:last-child {
        margin: 0;
      }

      svg {
        font-size: 8px;
        color: #fff;
      }
    }
  }
}

.table tr:hover {
  background-color: transparent;
}

.table td,
.table th {
  padding: 10px 15px;
  vertical-align: top;
  border-top: 1px solid #f2f4f9;
}

.table {
  a {
    color: #31af8f;
  }
}

.mdb-datatable-filter {
  margin-bottom: 1rem !important;

  input {
    padding: 18px 10px;
    font-size: 13px;
    box-shadow: none !important;
  }
}

.dataTables_info {
  font-size: 13px;
  margin-top: 1rem;
  margin-left: 1rem;
}

.mdb-datatable-filter {
  margin-right: 1rem;
  margin-top: 1rem;
}

.dataTables_paginate {
  float: right;
  font-size: 13px;
  margin-top: 1rem;
  padding-right: 1rem;

  .page-link {
    border: none;
    cursor: pointer;
    color: #31af8f;
  }

  li {
    &.active {
      .page-link {
        background-color: #31af8f;
      }
    }
  }
}

tbody {
  font-size: 13px;
  font-weight: 400;
}

.modal {
  form {
    font-size: 14px;

    .form-group {
      label {
        font-weight: 600;
        font-size: 13px;
      }
    }
  }

  input {
    // height: 43px;
    font-size: 12px;
    font-weight: 400;
    border-color: #bfc4ce;
    box-shadow: none !important;
    border-color: #4bb59b !important;
    border-radius: 6px;
    border-width: 1.5px;
  }

  select {
    height: 43px;
    font-size: 12px;
    font-weight: 400;
    border-color: #bfc4ce;
    box-shadow: none !important;
    border-color: #4bb59b !important;
    border-radius: 6px;
    border-width: 1.5px;
  }
}

@font-face {
  font-family: GraphikBold;
  src: url(../fonts/GraphikBold.otf);
}

@font-face {
  font-family: GraphikLight;
  src: url(../fonts/GraphikLight.otf);
}

@font-face {
  font-family: GraphikThin;
  src: url(../fonts/GraphikThin.otf);
}

@font-face {
  font-family: GraphikMedium;
  src: url(../fonts/GraphikMedium.otf);
}

@font-face {
  font-family: GraphikRegular;
  src: url(../fonts/GraphikRegular.otf);
}

.pagination {
  display: flex;
  justify-content: flex-end;

  .MuiButtonBase-root {
    padding: 7px 9px !important;
    font-size: 11px;
    background-color: #fff;
    color: rgb(170, 152, 152);
    border-radius: 4px;

    &.Mui-selected {
      background-color: #31af8f !important;
      cursor: default;
      color: #fff;
    }
  }
}

.spinn {
  background-color: #31af8f !important;
}

Spinner {
  background-color: #31af8f !important;
}